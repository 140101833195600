import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';
import axios from 'axios';
import { Link as GoLink } from 'react-scroll/modules';
import Layout from '../../../components/layout';
import Seo from '../../../components/seo';
import ListBrand from '../../../components/list-brand';
import ListLink from '../../../components/list-link';
import Image from '../../../components/Image';

const MorsoPage = () => {
  const [itemList, setItemList] = useState('');
  // Ajax設定
  const configJson = {
    method: 'get',
    url: '/update/brand/leda/index.json',
    headers: {
      Accept: 'application/json',
    },
    data: '',
  };
  // Ajax実行
  const dataFetch = async () => {
    axios(configJson)
      .then((response) => {
        setItemList(response.data);
      })
      .catch(() => {
        // console.log(error);
      });
  };
  // List生成
  const itemListArray = (items) => (
    <ul className="flex flex-wrap -mx-4">
      {items.map((item) => (
        <li className="item-content md:w-3/12 w-1/2 px-4" key={item}>
          <Link to={`/brand/leda/detail/?id=${item}`}>
            <img
              className="full-image"
              src={`/update/brand/leda/img/${item}.png`}
              alt=""
            />
            <p>{item.toUpperCase()}</p>
          </Link>
        </li>
      ))}
    </ul>
  );
  // Event
  useEffect(() => {
    dataFetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Layout page="brand-page leda">
      <Seo title="LEDA" />
      <section className="page-head">
        <ListBrand />
        <ListLink />
        <h2 className="main-title">
          <Image filename="logo/leda.png" width={180} />
        </h2>
      </section>
      <section className="page-content flex-layout">
        <div className="main-content">
          <div className="content-block">
            <h3 className="sub-title">
              <span>商品一覧</span>
            </h3>
            <p>ドイツ北西部ニーダーザクセン州。その中でも西部のオランダ国境に近い都市レーア（Leer）で1873年にLEDA社は鋳物工場を立ち上げました。それ以来150年近くに亘り工業大国ドイツの鋳物産業を牽引しています。創業当時は薪ストーブではなく産業用の鋳物製品を主としておりそこで培われた高い鋳物生産技術を薪ストーブに活かしています。</p>
          </div>
          {itemList
            ? itemList.map((item) => (
                <div
                  id={item.category}
                  className="content-block"
                  key={item.category}
                >
                  <h3 className="sub-title">
                    {item.category.replace('_', ' ').toUpperCase()}
                  </h3>
                  {itemListArray(item.items)}
                </div>
              ))
            : null}
          <div id="pdf" className="content-block">
            <a className="btn pdf full" href="/update/pdf/andersen.pdf" target="_blank">
              PDFカタログ ダウンロード
            </a>
          </div>
        </div>
        <div className="side-list">
        <ul className="sticky">
            {itemList
              ? itemList.map((item) => (
                  <li key={item.category}>
                    <GoLink to={item.category} spy smooth duration={800}>
                      {item.category.replace('_', ' ').toUpperCase()}
                    </GoLink>
                  </li>
                ))
              : null}
            <li>
              <GoLink className="c-attention" to="pdf" spy smooth duration={800}>
                PDFカタログ
              </GoLink>
            </li>
          </ul>
        </div>
      </section>
      <section className="bread">
        <ul>
          <li>
            <Link to="/">
              <Image filename="ico/home.png" />
            </Link>
          </li>
          <li>
            <Link to="/brand/">ブランド</Link>
          </li>
          <li>
            <Link to="/brand/leda/">LEDA</Link>
          </li>
          <li>LEDA商品一覧</li>
        </ul>
      </section>
    </Layout>
  );
};

export default MorsoPage;
